import { css } from "@emotion/react";
import { italic } from "./mixins";
import { fonts, colors, boxShadows } from "./variables";

export default css`
	html {
		box-sizing: border-box;
		font-size: 62.5%; /* 62.5% * 16px = 10px for most users */
		line-height: ${fonts.lineHeight.regular};
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	*::-moz-selection,
	*::selection {
		background-color: ${colors.sea};
	}

	body {
		width: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		font-family: ${fonts.adelphi};
		color: black;
		background-color: ${colors.white};
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		font-optical-sizing: none;
	}

	code {
		font-family: ${fonts.mono};
	}

	a {
		text-decoration: none;
		color: inherit;
		${boxShadows.anchorTagUnderline}
		transition: box-shadow 0.25s ease-out;

		&:hover,
		&:focus {
			text-decoration: none;
			${boxShadows.anchorTagBackground}
		}
	}

	button {
		&:hover {
			cursor: pointer;
		}
	}

	img {
		max-width: 100%;
		object-fit: contain;
		position: relative;
	}

	figure {
		margin: 2rem 0;
	}

	figcaption {
		font-size: 80%;
	}

	table {
		width: 100%;
		margin-bottom: 1rem;
		border: 1px solid ${colors.gray.line};
		font-size: 85%;
		border-collapse: collapse;
	}

	td,
	th {
		padding: 0.25rem 0.5rem;
		border: 1px solid ${colors.gray.line};
	}

	th {
		text-align: left;
	}

	tbody {
		tr {
			&:nth-of-type(odd) {
				td {
					background-color: ${colors.beige};
				}
				tr {
					background-color: ${colors.beige};
				}
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.414rem; /* ToDo: revise: should margin be set within normalize? */
		margin-bottom: 0.5rem; /* ToDo: revise: should margin be set within normalize? */
		font-family: ${fonts.adelphi};
		color: ${colors.almostBlack};
		line-height: ${fonts.lineHeight.regular1};
		font-weight: ${fonts.fontWeight.bold};
		text-rendering: optimizeLegibility;
	}

	p {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	strong {
		color: ${colors.almostBlack};
		font-weight: ${fonts.fontWeight.strong};
	}

	em {
		${italic};
	}

	ul,
	ol,
	dl {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	dt {
		font-weight: bold;
	}

	dd {
		margin-bottom: 0.5rem;
	}

	hr {
		position: relative;
		margin: 1.5rem 0;
		border: 0;
		border-top: 1px solid ${colors.gray.line};
	}

	blockquote {
		margin: 0.8rem 0;
		padding: 0.5rem 1rem;
		border-left: 0.25rem solid ${colors.gray.line};
		color: ${colors.gray.text};

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;
