// This needs to be a client component because of Chakra UI (emotion)
"use client";

import { CacheProvider } from "@chakra-ui/next-js";
import { ChakraProvider } from "@chakra-ui/react";
import { GlobalStyles } from "src/styles/GlobalStyles";
import theme from "src/styles/theme";

export const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<CacheProvider>
			<ChakraProvider theme={theme}>
				{children}
				<GlobalStyles />
			</ChakraProvider>
		</CacheProvider>
	);
};
