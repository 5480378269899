import { extendTheme, ThemeOverride } from "@chakra-ui/react";
import { breakpoints as bp, textPresets } from "./variables";

// https://github.com/chakra-ui/chakra-ui/issues/2609#issuecomment-737289774
const components = {
	Popover: {
		variants: {
			responsive: {
				popper: {
					maxWidth: "unset",
					width: "unset",
				},
			},
		},
	},
};

const overrides: ThemeOverride = {
	fonts: {
		heading: "var(--font-adelphi)",
		body: "var(--font-adelphi)",
		mono: "var(--font-silka)",
	},
	breakpoints: {
		xs: "0px",
		sm: bp.sm + "px",
		md: bp.md + "px",
		lg: bp.lg + "px",
		xl: bp.xl + "px",
		"2xl": bp["2xl"] + "px",
	},
	components,
	// See https://github.com/chakra-ui/chakra-ui/blob/6a0b63dd1b0b78a1d20f46594a8948a68768c2e9/packages%2Ftheme%2Fsrc%2Fstyles.ts#L7
	styles: {
		global: {
			// the body is empty on purpose
			body: {
				fontFamily: undefined,
				lineHeight: "inherit",
			},
			".chakra-toast__inner": {
				...textPresets.size7,
			},
			".chakra-alert__title": {
				marginBottom: "1rem",
			},
		},
	},
};

const theme = extendTheme(overrides);

export default theme;
